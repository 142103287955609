const createHash = require('create-hash');
const { getViewerName } = require('./pigeonhole-utils');

module.exports = (function Pigeonhole () {
  function getIFrameHTML (video) {
    return video?.customFields?.chat_embed;
  }

  function getIFrameHref (video) {
    const iframeHtml = getIFrameHTML(video);
    const urlRX = /((http|ftp|https):\/\/)*([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/;
    const [href] = urlRX.exec(iframeHtml) || [];
    return href || '';
  }

  function getIFrameURL (video) {
    const iframeHref = getIFrameHref(video);
    let iframeURL;
    try {
      iframeURL = new URL(iframeHref);
    } catch (err) {
      return null;
    }
    return iframeURL;
  }

  function getPigeonholePasscode (video) {
    const iframeURL = getIFrameURL(video);
    const pigeonholePassCode = iframeURL.pathname.split('/')[1];

    return pigeonholePassCode || '';
  }

  function validatePigeonhole (video) {
    if (!getIFrameHTML(video)) {
      return false;
    }

    const iframeHref = getIFrameHref(video);
    if (!iframeHref) {
      console.error('Error building Pigeonhole widget - Bad iframe href. Check the chat_embed custom field value.');
      return false;
    }

    if (!getIFrameURL(video)) {
      console.error(`Error building Pigeonhole widget - Invalid URL ${iframeHref}`);
      return false;
    }

    if (!getPigeonholePasscode(video)) {
      console.error('Error building Pigeonhole widget - Missing passcode');
      return false;
    }

    return true;
  }

  function getPigeonholeTag (video) {
    if (!validatePigeonhole(video)) {
      return '';
    }

    let iframeHtml = getIFrameHTML(video);
    let iframeHref = getIFrameHref(video);
    let iframeURL = getIFrameURL(video);

    const { samlAttributes } = global.bcGallery.filteredAssemblerData;

    const isValid = !!samlAttributes?.nameID;

    if (isValid) {
      const { nameID } = samlAttributes;
      const viewerName = getViewerName(samlAttributes);

      const pigeonholePassCode = getPigeonholePasscode(video);
      const attendeeCode = createHash('sha512').update(nameID)
        .digest('hex')
        .slice(0,20);

      iframeHref = iframeHref.replace(pigeonholePassCode, `${pigeonholePassCode}-${attendeeCode}`);
      iframeHtml = iframeHtml.replace(pigeonholePassCode, `${pigeonholePassCode}-${attendeeCode}`);

      const hrefWithPHName = `${iframeHref}${!iframeURL.search ? (iframeHref.charAt(iframeHref.length - 1) === '?' ? '' : '?') : '&'}phName=${encodeURIComponent(viewerName)}`;

      iframeHtml = iframeHtml.replace(iframeHref, hrefWithPHName);
      iframeHref = hrefWithPHName;
      iframeURL = new URL(iframeHref);
    }

    if (iframeURL.pathname.endsWith('/')) {
      iframeHtml = iframeHtml.replace(iframeURL.pathname, iframeURL.pathname.slice(0, -1));
    }

    return iframeHtml;
  }

  return {
    validatePigeonhole,
    getPigeonholeTag,
  };
})();
